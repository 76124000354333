#board {
  display: grid;
  grid-template-columns: repeat(16, 32px);
  grid-template-rows: repeat(17, 32px);
  margin: 3px;
}

#board>div {
  width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}
